export default (results) => {
  const max_meses = Math.max.apply(
    Math,
    results.map(function (o) {
      return o.meses;
    })
  );
  const min_meses = Math.min.apply(
    Math,
    results.map(function (o) {
      return o.meses;
    })
  );
  const max_cuota = Math.max.apply(
    Math,
    results.map(function (o) {
      return o.cuota;
    })
  );
  const min_cuota = Math.min.apply(
    Math,
    results.map(function (o) {
      return o.cuota;
    })
  );
  const max_comision = Math.max.apply(
    Math,
    results.map(function (o) {
      return o.CD;
    })
  );
  const min_comision = Math.min.apply(
    Math,
    results.map(function (o) {
      return o.CD;
    })
  );

  return {
    max_meses,
    min_meses,
    max_cuota,
    min_cuota,
    max_comision,
    min_comision,
  };
};
