export default (banco) => {
  let color;
  switch (banco) {
    case "Cofidis Salon VO":
      color = "orange";
      break;
    case "Cetelem VO (Val,Mad,Zar)":
      color = "orange";
      break;
    case "Santander Salon VO":
      color = "orange";
      break;
    default:
      color = "#000000";
      break;
  }

  return color;
};
