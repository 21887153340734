export default (fecha) => {
  var fechaActual = new Date();
  var diffYears =
    parseInt(fechaActual.getFullYear()) - parseInt(fecha.getFullYear());
  var diffMonths = parseInt(fechaActual.getMonth() - fecha.getMonth());
  var diffDays = parseInt(fechaActual.getDate()) - parseInt(fecha.getDate());

  var months = diffYears * 12 + diffMonths;
  if (diffDays > 0) months = months + 1;

  return months;
};
