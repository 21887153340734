export default (meses) => {
  var fechaInicio = new Date();
  fechaInicio.setMonth(fechaInicio.getMonth() + parseInt(meses));
  return (
    fechaInicio.getDate() +
    "/" +
    fechaInicio.getMonth() +
    "/" +
    fechaInicio.getFullYear()
  );
};
