import React, { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { post } from "./services/axiosCalls";
import getAntiguedad from "./helpers/getAntiguedad";
import getAntiguedadBBVA from "./helpers/getAntiguedadBBVA";
import calcularMaximoFinanciacion from "./helpers/calcularMaximoFinanciacion";
import getMinsMaxs from "./helpers/getMinsMaxs";
import getFinFinanciacion from "./helpers/getFinFinanciacion";
import colorize from "./helpers/colorize";
import colorizeText from "./helpers/colorizeText";
import { useDebouncedEffect } from "./helpers/useDebouncedEffect";
import { errorToast, successToast } from "./helpers/toastFunctions";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const App = () => {
  const [tab, setTab] = useState(2);
  const [log, setLog] = useState();

  const [cuota, setCuota] = useState(0);
  const [importe, setImporte] = useState();
  const [fecha, setFecha] = useState();
  const [meses, setMeses] = useState(0);
  const [tinMin, setTinMin] = useState(8.8);
  const [tinMax, setTinMax] = useState(11);
  const [tipoSeguro, setTipoSeguro] = useState("Seguro Básico");
  const [tipoCliente, setTipoCliente] = useState("Particular");

  const [results, setResults] = useState([]);

  const [registro, setRegistro] = useState([]);

  const [farRegisters, setFarRegisters] = useState();
  const [finFinanciacion, setFinFinanciacion] = useState();

  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);

  useEffect(() => {
    console.log(cookies.get("token"));
    if (cookies.get("token")) {
      checkToken(cookies.get("token"));
    }
  }, []);

  useDebouncedEffect(() => active && launchSearch(), 500, [
    cuota,
    importe,
    fecha,
    meses,
    tinMax,
    tinMin,
    tipoCliente,
    tipoSeguro,
  ]);

  useEffect(() => {
    if (results.length != 0) {
      const copyResults = results;
      console.log(copyResults);
      setFinFinanciacion(getFinFinanciacion(meses));
      setFarRegisters(getMinsMaxs(results));

      let registroArr = [];
      copyResults.map((r) => {
        const b = registroArr.includes(
          r.banco === "Caixabank (cliente caixa)"
            ? "Caixabank"
            : r.banco === "Caixabank (recta final hasta sep)"
            ? "Caixabank"
            : r.banco
        );
        console.log(b);
        if (!b) {
          registroArr.push(
            r.banco === "Caixabank (cliente caixa)"
              ? "Caixabank"
              : r.banco === "Caixabank (recta final hasta sep)"
              ? "Caixabank"
              : r.banco
          );
          r.status = 1;
        }
      });
      setResults(copyResults);
      setRegistro(registroArr);
    }
  }, [results]);

  const checkToken = async (token) => {
    const response = await post(
      "https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=" + token
    );
    if (response?.expires_in > 0) {
      const us = response.email.split("@")[0];
      console.log(us);
      setLog(us);
    }
  };

  const responseGoogleIn = (response) => {
    console.log(response);
    if (response.accessToken) {
      setLog(response.profileObj.email);
      cookies.set("token", response.accessToken, { path: "/" });
    }
  };

  const responseGoogleOut = () => {
    setLog();
    cookies.set("token", { path: "/" });
  };

  const launchSearch = async () => {
    if (!importe || !fecha || (!cuota && tab === 1) || (!meses && tab === 2)) {
      setFinFinanciacion();
      setResults([]);
      return setMessage("Por favor rellene todos los campos marcados con *.");
    } else {
      setMessage("");
    }
    const data = {
      usuario: log,
      cuota,
      importe,
      antiguedad: getAntiguedad(new Date(fecha)),
      antiguedadBBVA: getAntiguedadBBVA(new Date(fecha)),
      meses,
      tinMin,
      tinMax,
      tipoSeguro,
      tipoCliente,
    };
    const response = await post("/api/receive_data", { data });
    if (response) {
      setActive(true);
      setResults(response);
    }
  };

  return (
    <div className="App">
      {log ? (
        <div>
          <header>
            <div className="int">
              {/*               <div id="headerLeft">APP FINANCIACIÓN</div> */}
              <div id="headerCenter">
                <img src={process.env.PUBLIC_URL + "/logo.png"} />
              </div>
              {/*               <div id="headerRight">
                <GoogleLogout
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Cerrar sesión"
                  onLogoutSuccess={() => responseGoogleOut()}
                ></GoogleLogout>
              </div> */}
            </div>
          </header>

          <div class="content">
            <div class="row">
              <div className="col-md-6">
                <div className="tab block1 blur">
                  <div>
                    <span
                      className={`btn btn-sm ${
                        tab === 2
                          ? " btn btn-secondary"
                          : " btn-outline-secondary"
                      }`}
                      onClick={() => setTab(2)}
                    >
                      MESES
                    </span>
                    <span
                      className={`btn btn-sm ${
                        tab === 1
                          ? " btn btn-secondary"
                          : " btn-outline-secondary"
                      }`}
                      onClick={() => setTab(1)}
                    >
                      CUOTA
                    </span>
                  </div>

                  <div class="container">
                    <div class="row">
                      {tab === 1 ? (
                        <div class="col-4">
                          <h6 for="meses">Cuota*</h6>
                          <input
                            placeholder="Cuota"
                            type="number"
                            value={cuota ? cuota : ""}
                            onChange={(e) => setCuota(e.target.value)}
                          />
                        </div>
                      ) : (
                        <div class="col-4">
                          <h6 for="meses">Meses financiación*</h6>
                          <select
                            onChange={(e) => setMeses(e.target.value)}
                            value={meses}
                            id="meses"
                            name="meses"
                          >
                            <option value="0"> </option>
                            <option value="12">12</option>
                            <option value="24">24</option>
                            <option value="36">36</option>
                            <option value="48">48</option>
                            <option value="60">60</option>
                            <option value="72">72</option>
                            <option value="84">84</option>
                            <option value="96">96</option>
                            <option value="108">108</option>
                            <option value="120">120</option>
                          </select>
                        </div>
                      )}
                      <div class="col-4">
                        <h6 for="meses">Matriculación vehículo*</h6>
                        <input
                          type="date"
                          id="start"
                          name="trip-start"
                          value={fecha ? fecha : ""}
                          onChange={(e) => setFecha(e.target.value)}
                        />
                      </div>
                      <div class="col-4">
                        <h6 for="meses">Importe a financiar*</h6>
                        <input
                          placeholder="Importe"
                          type="number"
                          value={importe ? importe : ""}
                          onChange={(e) => setImporte(e.target.value)}
                        />
                      </div>
                    </div>

                    {active && (
                      <div>
                        <div class="row">
                          {tab === 1 ? (
                            <div class="col-4">
                              <h6 for="meses">Meses financiación</h6>
                              <select
                                onChange={(e) => setMeses(e.target.value)}
                                value={meses}
                                id="meses"
                                name="meses"
                              >
                                <option value="0"> </option>
                                <option value="12">12</option>
                                <option value="24">24</option>
                                <option value="36">36</option>
                                <option value="48">48</option>
                                <option value="60">60</option>
                                <option value="72">72</option>
                                <option value="84">84</option>
                                <option value="96">96</option>
                                <option value="108">108</option>
                                <option value="120">120</option>
                              </select>
                            </div>
                          ) : (
                            <div class="col-4">
                              <h6 for="meses">Cuota</h6>
                              <input
                                placeholder="Cuota"
                                type="number"
                                value={cuota ? cuota : ""}
                                onChange={(e) => setCuota(e.target.value)}
                              />
                            </div>
                          )}
                          <div class="col-4">
                            <h6 for="tinMin">TIN Min {tinMin}</h6>
                            <input
                              type="range"
                              id="tinMin"
                              max="12"
                              min="2.9"
                              step="0.1"
                              value={tinMin}
                              onChange={(e) => setTinMin(e.target.value)}
                            />
                          </div>
                          <div class="col-4">
                            <h6 for="tinMax">TIN Max {tinMax}</h6>
                            <input
                              type="range"
                              id="tinMax"
                              max="15"
                              min="3.9"
                              step="0.1"
                              value={tinMax}
                              onChange={(e) => setTinMax(e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3">
                            <h6>Tipo de seguro</h6>
                            <input
                              type="radio"
                              id="tipoSeguroA"
                              onClick={() => setTipoSeguro("Seguro Básico")}
                              checked={
                                tipoSeguro === "Seguro Básico" ? true : false
                              }
                            />
                            <label for="tipoSeguroA">Básico</label>
                            <br />
                            <input
                              type="radio"
                              id="tipoSeguroB"
                              onClick={() => setTipoSeguro("Seguro Completo")}
                              checked={
                                tipoSeguro === "Seguro Completo" ? true : false
                              }
                            />
                            <label for="tipoSeguroB">Completo</label>
                            <br />
                            <input
                              type="radio"
                              id="tipoSeguroC"
                              onClick={() => setTipoSeguro("Sin Seguro")}
                              checked={
                                tipoSeguro === "Sin Seguro" ? true : false
                              }
                            />
                            <label for="tipoSeguroC">Sin seguro</label>
                          </div>
                          <div class="col-4">
                            <h6>Tipo de cliente</h6>
                            <input
                              type="radio"
                              id="tipoClienteA"
                              onClick={() =>
                                setTipoCliente("Particular")
                              }
                              checked={
                                tipoCliente === "Particular"
                                  ? true
                                  : false
                              }
                            />
                            <label for="tipoClienteA">
                              Particular/Autónomo
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="tipoClienteB"
                              onClick={() => setTipoCliente("Empresa")}
                              checked={tipoCliente === "Empresa" ? true : false}
                            />
                            <label for="tipoClienteB">Empresa</label>
                          </div>
                          <div class="col-5 listS">
                            {registro.length != 0 ? (
                              <span className="f12 bold">
                                Máx. meses a financiar:
                                <br />
                              </span>
                            ) : (
                              ""
                            )}
                            {fecha &&
                              importe &&
                              registro.map((banco) => {
                                return (
                                  <span className="f12">
                                    {banco} -
                                    {calcularMaximoFinanciacion(
                                      banco,
                                      new Date(fecha),
                                      importe
                                    )}
                                    <br />
                                  </span>
                                );
                              })}
                            <button
                              className="d-flex justify-content-center btn-calcular btn-calcular-full mt-2"
                              onClick={() => launchSearch()}
                            >
                              CALCULAR
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {!active && (
                      <button
                        className="d-flex justify-content-center btn-calcular"
                        onClick={() => launchSearch()}
                      >
                        CALCULAR
                      </button>
                    )}
                  </div>
                </div>
                <div className="tab block2 blur">
                  {active && (
                    <table class="table">
                      <thead class="thead-mini">
                        <tr>
                          <th scope="col ">Cuota mínima</th>
                          <th scope="col">Cuota máxima</th>
                          <th scope="col">Meses mínimo</th>
                          <th scope="col">Meses máximo</th>
                          <th scope="col">Comisión mínima</th>
                          <th scope="col">Comisión máxima</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="f15">{farRegisters?.min_cuota}</td>
                          <td className="f15">{farRegisters?.max_cuota}</td>
                          <td className="f15">{farRegisters?.min_meses}</td>
                          <td className="f15">{farRegisters?.max_meses}</td>
                          <td className="f15">{farRegisters?.min_comision}</td>
                          <td className="f15">{farRegisters?.max_comision}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="tab block3 blur">
                  {finFinanciacion ? (
                    <span>Fecha fin financiación: {finFinanciacion}</span>
                  ) : (
                    message
                  )}

                  {active && (
                    <div>
                      <div>
                        <table class="table table-nm">
                          <thead>
                            <tr>
                              <th style={{ width: "37%" }} scope="col">
                                BANCO
                              </th>
                              <th scope="col">TIN</th>
                              <th scope="col">MESES</th>
                              <th scope="col">CUOTA MES</th>
                              <th scope="col">
                                CD &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>

                      <div className="tab block4 blur fixedBox">
                        <table class="table">
                          <tbody>
                            {results?.map((r) => {
                              return (
                                <tr
                                  style={{
                                    backgroundColor: r.status
                                      ? `${colorize(r.banco)}`
                                      : "",
                                    color: r.status
                                      ? `${colorizeText(r.banco)}`
                                      : "",
                                  }}
                                >
                                  <td style={{ width: "40%" }}>{r.banco}</td>
                                  <td>{r.tae}</td>
                                  <td>{r.meses}</td>
                                  <td>{r.cuota}</td>
                                  <td>{parseFloat(r.CD).toFixed(2)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div style={{ padding: "300px" }}>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Iniciar sesión"
              onSuccess={responseGoogleIn}
              onFailure={responseGoogleIn}
              cookiePolicy={"single_host_origin"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
