import getAntiguedad from "./getAntiguedad";
import getAntiguedadBBVA from "./getAntiguedadBBVA";

export default (banco, fecha, importe) => {
  var maximoMesesFinanciacion;

  var mesesAntiguedad;
  var ajusteMeses = 12;
  /*   if (banco === "Caixabank (cliente caixa)") {
    banco = "Caixabank";
  } */
  if (banco === "BBVA") mesesAntiguedad = getAntiguedadBBVA(fecha);
  else mesesAntiguedad = getAntiguedad(fecha);

  if (mesesAntiguedad % 12 === 0) ajusteMeses = 0;

  if (banco === "Cetelem")
    maximoMesesFinanciacion =
      144 - mesesAntiguedad + (mesesAntiguedad % 12) - ajusteMeses;
  // if (banco === 'Cetelem 3M Carencia')
  // maximoMesesFinanciacion = 144 - mesesAntiguedad + mesesAntiguedad%12 - ajusteMeses;
  //Cofigis tiene una lógica específica en el intervalo 60-72 meses
  if (banco === "Cofidis")
    if (mesesAntiguedad < 73)
      maximoMesesFinanciacion =
        192 - mesesAntiguedad + (mesesAntiguedad % 12) - ajusteMeses;
    else
      maximoMesesFinanciacion =
        156 - mesesAntiguedad + (mesesAntiguedad % 12) - ajusteMeses;
  else if (banco === "BBVA")
    maximoMesesFinanciacion =
      156 - mesesAntiguedad + (mesesAntiguedad % 12) - ajusteMeses;
  else if (banco === "Abanca")
    maximoMesesFinanciacion =
      180 - mesesAntiguedad + (mesesAntiguedad % 12) - ajusteMeses;
  else if (banco === "Sofinco")
    maximoMesesFinanciacion =
      180 - mesesAntiguedad + (mesesAntiguedad % 12) - ajusteMeses;
  else if (banco === "Santander")
    maximoMesesFinanciacion =
      156 - mesesAntiguedad + (mesesAntiguedad % 12) - ajusteMeses;
  else if (banco === "Santander 3M Carencia")
    maximoMesesFinanciacion =
      156 - mesesAntiguedad + (mesesAntiguedad % 12) - ajusteMeses;
  else if (banco === "Caixabank")
    maximoMesesFinanciacion =
      120 - mesesAntiguedad + (mesesAntiguedad % 12) - ajusteMeses;

  if (maximoMesesFinanciacion > 120) maximoMesesFinanciacion = 120;
  //Sofinco no financia más de 84 meses si no superas los 10k
  else if (
    importe <= 10000 &&
    maximoMesesFinanciacion > 84 &&
    banco === "Sofinco"
  )
    maximoMesesFinanciacion = 84;

  return maximoMesesFinanciacion ? maximoMesesFinanciacion.toString() : null;
};
