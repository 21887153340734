
const colorMap = {
  "#8cd3ff": ["Sabadell"],
  "#EB1620": ["Santander"],
  "#007EAE": ["Caixabank", "Caixabank (cliente caixa)", "Caixabank (recta final hasta sep)", "Caixabank (Sin Seguro)"],
  "#BFFF00": ["Sofinco"],
  "#006EC1": ["BBVA", "BBVA (Sin Seguro)"],
  "#558806": ["Cetelem", "Cetelem (Sin Seguro)"],
  "#5F88C2": ["Abanca"],
  "#f7a600": ["Cofidis"],
  "#FFE925": ["Lendrock"],
  "#E30071": ["CA-Autobank"],
};

const defaultColor = "#fff";

const colorize = (banco) => Object.keys(colorMap).find((colorKey) => colorMap[colorKey].includes(banco)) || defaultColor;

export default colorize;